import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

/* Action Types */
const SET_USER = 'SET_USER';
const SET_DEALER = 'SET_DEALER';

/* Define a context and a reducer for updating the context */
const GlobalStateContext = React.createContext();

/* Initial Globale State */
const initialState = {
  user: {
    firstName: null,
    particleName: null,
    lastName: null,
    status: null,
    gender: null,
    birthday: new Date('01/01/1970').toString(),
    email: null,
  },
  dealer: {
    name: null,
    id: null
  },
  currentPage: null,
}

const globalStateReducer = (state, action) => {
  console.log('%c reducer state', 'background: #222; color: #bada55');
  console.log(action)
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: { ...action.payload },
      };
    case SET_DEALER:
      return {
        ...state,
        dealer: { ...action.payload },
      };

    default:
      return state;
  }
};

/* Export GlobalContextProvider to be used in its children */
export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    globalStateReducer,
    initialState
  );

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};


/*
 * Default export a hook that updates the global state
 */
const useGlobalState = () => {
  const [state, dispatch] = React.useContext(GlobalStateContext);

  /** TODO Use an array */
  const setUser = ({
    firstName,
    particleName,
    lastName,
    status,
    gender,
    birthday,
    email
  }) => {
    dispatch({
      type: SET_USER,
      payload: {
        firstName,
        particleName,
        lastName,
        status,
        gender,
        birthday,
        email
      }
    })
  };

  const setDealer = ({
    name,
    id
  }) => {
    dispatch({
      type: SET_DEALER,
      payload: {
        name,
        id
      }
    })
  };

  return {
    setUser,
    user: {...state.user},
    setDealer,
    dealer: {...state.dealer},
  };
}

export default useGlobalState;