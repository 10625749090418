module.exports = [{
      plugin: require('../../../shared/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nissan NOW","short_name":"Nissan NOW","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/assets/images/youplus-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"946d4e4b986ab320cee6e4023d569119"},
    },{
      plugin: require('../../../shared/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../shared/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/www/loyalty.nissan.nl/front/releases/20240322155829/src/intl","languages":["nl-NL","nl-BE","fr-BE","fr-LU"],"defaultLanguage":"nl-NL","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
