import "./src/assets/sass/global.sass"

import React from 'react';
import { GlobalContextProvider } from './src/context/GlobalContextProvider';

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

export const onClientEntry = () => {
  // Récupérer l'URL actuelle
  const currentUrl = window.location.href;

  // Vérifier si "nl-NL" est présent dans l'URL
  if (currentUrl.includes('nl-NL')) {
    // Ajouter le script Google Analytics à la balise <head>
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-5W5FFL14TE';
    document.head.appendChild(script);

    // Ajouter le deuxième script pour la configuration de Google Analytics
    const configScript = document.createElement('script');
    configScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-5W5FFL14TE');
    `;
    document.head.appendChild(configScript);
  }
};
